<template>
  <modal-brandme :key="change_modal" :show_modal="modal_chat" closeIcon="close-white-card-section" :size="'lg'" @hidden="closeModal" :bg_title="'bg-modal-chat-section'" :bg_body="'bg-modal-body-chat-section'" :class_footer="'footer-modal-chat-section'">
    <template #title="">
        <span class="text-white"><b-img class="img-crown svg-crown" :src="require(`@/assets/images/svg/crown.svg`)"></b-img> 
          Se incluye en cualquier Plan de paga
        </span>
    </template>
    <template #body="">
      <b-row>
        <b-col>
          <h2 class="mr-10">{{ texts_modals[text_selected].title }}
          <b-badge class="mr-10 bgd-color" pill v-for="badge in texts_modals[text_selected].badge" :key="badge">{{ badge }}</b-badge>
          </h2>

        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-3 d-flex col-12">
          <span clasS="avenir-medium">{{ texts_modals[text_selected].description }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-img class="img-modal" :src="require(`@/assets/images/pages/${texts_modals[text_selected].image}.png`)"></b-img>
        </b-col>
        <b-col>
          <h2>Beneficios:</h2>
          <b-col class="mt-2" v-for="(item, index) in texts_modals[text_selected].benefits" :key="index">
            <b-icon icon="check" class="text-primary mr-10 h2 mb-0"></b-icon>
            <span clasS="avenir-medium">{{ item }}</span>
          </b-col>
        </b-col>
      </b-row>
    </template>
    <template #footer="">
      <b-button pill variant="ligth text-primary mr-1" @click="closeModal"> Cerrar</b-button>
      <b-button pill variant="primary" @click="goToMembership"><b-img class="img-crown-btn svg-crown" :src="require(`@/assets/images/svg/crown.svg`)"></b-img> Actualizar Membresía</b-button>
    </template>


  </modal-brandme>
</template>
  
<script>
import { BButton, BRow, BCol, BBadge, BImg } from "bootstrap-vue";

export default {
  name: "modalMemberhipSection",
  components: {
    BButton,
    BRow,
    BCol,
    BBadge,
    BImg,
    modalBrandme: () => import('@/views/components/modal/ModalBrandme.vue')
  },
  props: {
    text_selected_dad: {
      type: String,
      default: 'search'
    },
    open_modal: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      change_modal: false,
      modal_chat: this.open_modal,
      texts_modals: {
        search: {
          title: 'Buscador Global',
          badge: ['Influencers', 'Data'],
          description: 'Buscador de Influencers con una base de datos de más de 80 millones de cuentas.',
          benefits: ['Base de datos con más de 80 millones de Influencers', 'Búsqueda demográfica', 'Filtros de calidad de la audiencia', 'Descubre Influencers en Instagram, TikTok, Twitch, X ( antes Twitter ) y YouTube'],
          image: 'search_section',
        },
        analyzer: {
          title: 'Analizador de Cuentas',
          badge: ['Influencers', 'Data'],
          description: 'Analizador completo con más de 35 métricas parta analizar a profundidad influencers de Instagram, YouTube, X ( antes Twitter), TikTok y Twitch.',
          benefits: ['Demografía de la audiencia', 'Detectamos fraude en cuentas', 'Afinidad con la marca', 'Precios estimados por colaboración'],
          image: 'analyzer_section',
        },
        campaigns: {
          title: 'Campañas internas',
          badge: ['Influencers', 'Gestión'],
          description: 'Gestiona, supervisa, controla y mide tus campañas de influencer marketing internas.',
          benefits: ['Gestiona y ordena cada una de tus iniciativas de influencer marketing', 'Controla y mide por Squad de Infleuncers y Presupuesto', 'Envía invitaciones personalizadas a los influencers dentro y fuera de BrandMe', 'Obtén analytics de tu campaña de forma individual y general por campaña'],
          image: 'campaigns_section',
        },
        list: {
          title: 'Listas',
          badge: ['Influencers', 'Gestión'],
          description: 'Ordena por categoría influencers para tus campañas de influencer marketing',
          benefits: ['Ahorra tiempo ordenando por listas los influencer que mejor te funcionan', 'Agrega tus listas a campañas internas ahorrando tiempo', 'Comprara influencers dentro de tus listas por seguidores o ER', 'Exporta tus listas a Excel para trabajar los datos en tu computadora'],
          image: 'lists_section',
        },
        tracking_links: {
          title: 'Tracking Links',
          badge: ['Data', 'Gestión'],
          description: 'Crea URLs por campaña o Influencer y mide su rendimiento gracias al seguimiento de Clics',
          benefits: ['Seguimiento de Clics por cada URL', 'Detección de Clics Humanos ( reales ) vs Clics Bots', 'Seguimiento Demográfico', 'Seguimiento por Dispositivo'],
          image: 'tracking_section',
        }
      },
      text_selected: this.text_selected_dad,
    };
  },
  methods: {
    closeModal() {
      this.change_modal = !this.change_modal
      this.modal_chat = false
      this.$emit('close_modal')
    },
    goToMembership() {
      this.$router.push({ name: 'pages-account-setting', params: { section: 'membership' }}).catch(() => {})
      this.closeModal();
    }
  },
};
</script>
<style>
.close-white-card-section {
  display: block;
  position: absolute;
  left: 17px;
  color: white;
  margin: 0 1.3em 0 0;
}
.close-white-card-section:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.footer-modal-chat-section {
  display: flex;
  justify-content: end;
  background: rgb(245, 249, 253);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.bg-modal-chat-section {
  background: rgb(121, 117, 242);
  justify-content: center;
  display:flex
}
.bg-modal-body-chat-section {
  background: rgb(245, 249, 253);
}
</style>
<style lang="scss" scoped>
.svg-crown {
  filter: brightness(0) invert(1);
}
.img-crown {
  width: 28px;
  margin-right: .5em;
  vertical-align: bottom;
}
.bgd-color {
  font-size: 14px;
  font-weight: 500;
  background-color: rgba(181, 190, 198, 0.2);
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 4px 8px;
  color:black
}
.img-modal {
  width: 100%;
  border-radius: 5%;
}
.img-crown-btn {
  width: 20px;
  margin-right: .3em;
  vertical-align:text-bottom
}
.mr-10 {
  margin-right: 10px;
}
</style>